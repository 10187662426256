.App {
  text-align: left;
  /* font-family: 'Andale Mono', monospace; */
  margin: 0;
  padding: 0;
}

p {
  /* margin: 0 */
}



.user-profile {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  /* border-radius: 8px; */
  /* background-color: #f9f9f9; */
  text-align: left;
}

.nav-link:hover {
  color: grey; /* Change to grey on hover */
}
.user-profile h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 0px;
}

.user-profile p {
  font-size: 1rem;
  margin: 10px 0;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
