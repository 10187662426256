body {
  margin: 0;
  padding: 0;
  /* font-family: 'Andale Mono', monospace; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("./bg_images/black_bg.jpg"); */
  background-color: #000000; /* Dark background */
  color: #fff; /* Light text for contrast */

  
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #000000;
  padding: 10px;
  border: 1px solid #ddd;
  /* border-radius: 5px; */
  top: 100%; /* Position below the button */
  left: 0;
  z-index: 1000;
}
.button-image {
  width: 180px;
  cursor: pointer;
}
.dropdown-menu a {
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  transition: background 0.3s;
}


/* leaderboard.css */
.leaderboard {
  padding: 20px;
  text-align: center;
}

table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
}

table th, table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

table th {
  background-color: #f4f4f4;
  color:#000
}

code {
  font-family: "Andale Mono", "Courier New", Courier, monospace;
}

.App-header {
  background-color: #333;
  background-image: url("./bg_images/banner_sky_grass.jpg");
  background-size: cover;
  color: #fff;
  padding: 60px 20px; /* Increase padding for a more visually appealing header */
  text-align: center;
}
#linktree_nav_image {
  width: 40px;
}
#instagram_nav_image {
  width: 40px;
}
#spotify_nav_image {
  width: 40px;
}
#facebook_nav_image {
  width: 40px;
}
#youtube_nav_image {
  width: 40px;
}
.above_navigation {
  display: flex;
  position:sticky;
  background-color: #000;
  right: 0;
  z-index: 10000;
}

.media_links {
  margin-left: 50%;
  margin-top: 25px;

}
.media_links a {
  background-color: transparent;
  color: #fff;
  border: none;
  margin: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigation a, .navigation button {
  font-weight: bold
}

.navigation {
  display: flex;
  margin-bottom: 20px;
  margin-top: 0px;
  background-color: #000;
  position: sticky;
  top: 0;
  z-index: 1000;
  /* border-top: 2px solid #fff;
  border-bottom: 2px solid #fff; */
  border-left: none; /* No border on left */
  border-right: none; /* No border on right */
}

.navigation button {
  font-weight: bold;
  margin-top: 0.0px;
}

.navigation button, .navigation a {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  font-family: 'Andale Mono', monospace;
  /* text-shadow: 2px 2px 5px #a8a8a8; */
}

a:hover {
  color: #c700b9;
}

.navigation a:hover, .navigation button:hover {
  /* background-color: #0e0e0e; */
  color: #888888;
}

#snake-a:hover {
  color: #ffffff;
}

#snake-a {
  color: black;
}

.navigation .active {
  /* background-color: #0e0e0e; */
  color: #888888;
}

#lefthouse_banner_logo {
  width: 220px;
  display: flex;
  margin-top: 0px;
  background-color: #000;
  position: sticky;
  z-index: 1000;

}

.social-image {
  width: 200px;
}

#instagram_image {
  width: 100px;
}

#facebook_image, #spotify_image {
  width: 125px;
}

.gig-year-select {
  color: #fff;
  background-color: #00000000;
  border: none;
  padding: 10px 20px;
}

.select_button {
  background-color: #0e0e0e;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.select_button option {
  display: block;
  padding: 50px 20px;
}
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content.show {
  display: block;
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #555;
  cursor: pointer;
  color: #fff;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #555;
}

a {
  color: inherit;
  font-size: medium;
  text-decoration: inherit;
}



.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0;
}

div {
  margin: 0;
}

/* Gig section */
.gig-section {
  margin-bottom: 25px;
  background-color: #0e0e0e00; 
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 26px;
}

.gig-card h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
}

.gig-card p {
  margin: 5px 0;
}

#gig_poster {
  width: 200px;
}

.gig-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gig-details img {
  max-width: 100%;
  border-radius: 10px;
}

#cm_cover_img {
  width: 100%;
  max-width: 150px;
  border-radius: 10px;
}

.green_text {
  color: rgb(57, 139, 57);
}

.blue_text {
  color: rgb(58, 142, 163);
  font-size: 20px;
}


/* Song Lyrics Page */
.page.song-lyrics-page {
  padding: 20px;
  background-color: #0e0e0e; /* Dark background for song lyrics page */
  border-radius: 10px;
}

.lyrics-section h2 {
  margin-top: 10px;
  color: #fff;
}

.lyrics-section pre {
  padding: 10px;
  background: #0e0e0e;
  border-radius: 5px;
  white-space: pre-wrap;
  color: #fff;
}

.song-list {
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
}
.gig-poster {
  width: 150px;
}
.gig-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders don't double up */
  margin-top: 20px;
}

.gig-table th, 
.gig-table td {
  border: 1px solid white; /* White border for cells */
  padding: 12px;
  text-align: left;
  color: white; /* Text color */
}

.gig-table th {
  background-color: #0e0e0e; /* Darker background for headers */
}

.gig-table td {
  background-color: #111111; /* Slightly lighter background for cells */
}

.gig-table img {
  max-width: 100px;
  height: auto;
}

.gig-table a {
  color: white;
  text-decoration: none;
}

.gig-table a:hover {
  text-decoration: underline;
}

.song-item {
  cursor: pointer;
  color: #ccc;
  margin-bottom: 10px;
  transition: color 0.3s;
}

.song-item:hover {
  color: #007BFF;
}

/* Post.css */
.post-container {
  text-align: left;
  margin-bottom: 20px;
  word-wrap: break-word;
  /* border-radius: 10px; */
  padding: 10px;
  color: white;
  /* border: 1px solid white; */
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

.post-username {
  margin: 0 0 10px 0;
}

.post-content {
  margin-bottom: 10px;
}

.expand-button {
  border: none;
  background-color: transparent;
  color: #007BFF;
  cursor: pointer;
}

.expand-button:hover {
  color: #0056b3; /* Darker shade of blue on hover */
}

.post-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.like-button,
.dislike-button {
  background-color: #f1f1f1;
  color: #333;
  border: none;
  /* border-radius: 5px; */
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px;
}

.like-button:hover,
.dislike-button:hover {
  background-color: #ddd; /* Light gray background on hover */
}

.like-button.active {
  background-color: #4CAF50;
  color: white;
}

.like-button.active:hover {
  background-color: #45a049; /* Darker green on hover */
}

.dislike-button.active {
  background-color: #f44336;
  color: white;
}

.dislike-button.active:hover {
  background-color: #e53935; /* Darker red on hover */
}

.delete-button {
  color: red;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.delete-button:hover {
  color: #ff4d4d; /* Lighter red when hovered */
}

.post-date {
  margin-top: 10px;
  font-size: 12px;
  color: #fff;
}

.comments-section {
  margin-top: 20px;
}

.toggle-comments-button {
  background-color: #007BFF;
  color: white;
  border: none;
  /* border-radius: 5px; */
  padding: 10px;
  cursor: pointer;
}

.toggle-comments-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.comment-input {
  width: 93.5%;
  padding: 10px;
  background-color: rgb(24, 24, 24);
  /* border-radius: 5px; */
  border: 1px solid #ddd;
  margin-bottom: 10px;
  color: white;
}

.add-comment-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  /* border-radius: 5px; */
  padding: 10px;
  cursor: pointer;
}

.add-comment-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.comments-list {
  margin-top: 20px;
}

.comment {
  margin-bottom: 10px;
}

.comment.reply {
  margin-left: 20px;
  border-left: 1px solid #ccc;
  padding-left: 10px;
}

.comment-date {
  font-size: 12px;
  color: #aaa;
}

.reply-button {
  border: none;
  background-color: transparent;
  color: #007BFF;
  cursor: pointer;
  font-size: 12px;
}

.reply-button:hover {
  color: #0056b3; /* Darker blue on hover */
}

/*End of Post.css*/
/* Responsive styling */
@media screen and (max-width: 768px) {

  .media_links {
    display: none;
  }

  .navigation {
    font-size: 18px;
    flex-direction: column;
    align-items: flex-start;
    display:flex;
    position: relative;
  }

  .navigation a, .navigation button {
    padding: 10px;
    font-size: 18px;
    width: 100%;
    text-align: left;
  }

  #lefthouse_banner_logo {
    width: 220px;
    margin-bottom: 10px;
  }

  .container {
    padding: 15px;
  }

  .social-icons img {
    width: 20px;
  }
}
